<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      events: null,
    };
  },
  methods: {
    getList() {
      api
        .get('events')
        .then((response) => {
          if (response.data.status == 'success') {
            this.events = response.data.list
          }
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Eventos') }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!events" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div v-else-if="events && events.length == 0" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum evento encontrado
        </div>
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col-md-4" v-for="(row, index) in events" :key="index">
          <router-link tag="a" :to="'/events/' + row.id">
            <div class="card">
              <div class="card-body p-2">
                <img v-if="row.cover" class="event-cover" :src="row.cover" />
                <div class="p-2">
                  <h5>{{ row.name }}</h5>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                        <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path>
                        <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path>
                      </svg>
                      <div class="ml-1 text-dark font-weight-bold">{{ row.date }}</div>
                    </div>
                    <div>
                      <span class="bg-soft-default rounded px-1 text-dark" v-if="row.type == 'in_person'">PRESENCIAL</span>
                      <span class="bg-soft-default rounded px-1 text-dark" v-else>ONLINE</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.event-cover {
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 10 / 5;
}
</style>
